import type { LinkProps as NextLinkProps } from 'next/link';
import type { LinkProps as MuiLinkProps } from '@mui/material/Link';
import type { NextRouter } from 'next/router';

import * as React from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { styled } from '@mui/material/styles';
import NextTranslateLink from 'next-translate-routes/link';
import NextLink from 'next/link';
import MuiLink from '@mui/material/Link';
import { useCursorContext } from '../../contexts/CursorContext';

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({});

// Todo: TS2320: Interface 'NextLinkComposedProps' cannot simultaneously extend types 'Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">' and 'Omit<InternalLinkProps, "href" | "as">'.   Named property 'onClick' of types 'Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">' and 'Omit<InternalLinkProps, "href" | "as">' are not identical.
// @ts-ignore
interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<NextLinkProps, 'href' | 'as'> {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
  storybook?: boolean;
}

interface NextRouterProps extends NextRouter {
  storybook?: boolean;
}

export const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(function NextLinkComposed(
  props,
  ref
) {
  const { to, linkAs, replace, scroll, shallow, prefetch, locale, storybook, ...other } = props;

  return storybook ? (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
    >
      <Anchor ref={ref} {...other} />
    </NextLink>
  ) : (
    /* next-translate-routes/link is using the wrong Type for next/link. */
    <NextTranslateLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
    >
      <Anchor ref={ref} {...other} />
    </NextTranslateLink>
  );
});

export type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps['as'];
  href: NextLinkProps['href'];
  linkAs?: NextLinkProps['as']; // Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  const {
    activeClassName = 'active',
    as,
    className: classNameProps,
    href,
    linkAs: linkAsProp,
    locale,
    noLinkStyle,
    prefetch,
    replace,
    role, // Link don't have roles.
    scroll,
    shallow,
    ...other
  } = props;

  const { enabled } = useCursorContext();
  const router: NextRouterProps = useRouter();
  const storybook = router?.storybook;
  const pathname = typeof href === 'string' ? href : href?.pathname ?? '/';
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });

  const isExternal = typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

  if (isExternal) {
    if (noLinkStyle) {
      return <Anchor className={className} href={enabled ? '#' : href} ref={ref} {...other} />;
    }

    return <MuiLink className={className} href={enabled ? '#' : href} ref={ref} {...other} />;
  }

  const linkAs = linkAsProp || as;
  const nextjsProps = { to: enabled ? '#' : href, linkAs, replace, scroll, shallow, prefetch, locale, storybook };

  if (noLinkStyle) {
    return <NextLinkComposed className={className} ref={ref} {...nextjsProps} {...other} />;
  }

  return <MuiLink component={NextLinkComposed} className={className} ref={ref} {...nextjsProps} {...other} />;
});
